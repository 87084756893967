<template>
    <div>
        <kendo-grid
            ref="grid"
            :data-source="ItemFirstInFirstOutMutationReport"
            :filterable="false"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true">
        </kendo-grid>
    </div>
</template>
  
  
<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid, datetime_zone } from '../../../../infrastructure/constant/variable';
import moment from 'moment';
import itemFirstInFirstOutMutationReportServices from '../Script/ItemFirstInFirstOutMutationService';
  
export default {
    name: 'ItemFirstInFirstOutMutationReportrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['dateTo', 'dateFrom', 'item'],
    computed: {
        ItemFirstInFirstOutMutationReport () {
            var dateFrom = this.$props.dateFrom;
            var dateTo = this.$props.dateTo;
            var item = this.$props.item;

            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                startDate: dateFrom + datetime_zone.date_from,
                                endDate: dateTo + datetime_zone.date_to,
                                item: item == null ? null : item.value,
                            };
                            return {
                                query: itemFirstInFirstOutMutationReportServices.readItemFirstInFirstOutMutationReportQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) { 
                        if(response.data.GetReportingStockMutationFirstInOut == null) {
                            return [];
                        }
                        else {
                            return response.data.GetReportingStockMutationFirstInOut;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetReportingStockMutationFirstInOut == null) {
                            return 0;
                        }
                        else {
                            return response.data.GetReportingStockMutationFirstInOut.length;
                        }
                    }
                },
                group: [
                    {
                        field: "product_code"
                    }
                ],
            });
        }
    },
    data: function () {
        return  {
            columns:  [
                { field: "product_code", title: "Kode", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" },
                    groupHeaderTemplate: this.groupHeaderTemp, hidden: true },
                { field: "item_group_name", title: "Item Group", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
                { field: "type_detail_name", title: "Tipe Detail", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
                { field: "storage_id", title: "Gudang", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
                { field: "item_name", title: "Nama Item", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
                { field: "ref_number", title: "Nomor Transaksi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" } },
                { field: "ref_date", title: "Tanggal Transaksi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold; vertical-align:middle;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= ref_date == null ? '' : kendo.toString(kendo.parseDate(ref_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #",
                    filterable : {
                        ui: function (element) {
                            element.kendoDatePicker({
                                format: "yyyy-MM-dd"
                            });
                        }
                    } 
                },  
                { field:"stock_in", title: "Stock Masuk", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field:"stock_out", title: "Stock Keluar", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
                { field:"base_uom", title: "Satuan", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field:"purchasing_price", title: "Harga", width: 150, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes: { "class": "k-text-right" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {

    },
    methods: {
        groupHeaderTemp(data){
            var itemName = data.items[0].item_name;
            var currentStock = data.items[0].last_base_stock;
            return `Kode Item : ${data.value} (`+ itemName +`) <br> Current Stock : ` + currentStock;
        },
        async exportExcel(DateFrom, DateTo) {
            const variables = {
                startDate: DateFrom,
                endDate: DateTo
            };

            var gridData = this.$refs.grid.kendoWidget()._data;
            var data = await itemFirstInFirstOutMutationReportServices.itemFirstInFirstOutMutationReportExcel(gridData, variables);
            if (gridData == null) {
                this.$swal("Error", response.noRecord, "error")
            }
            else {
                var XLSX = require("xlsx");
                var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

                var filename = 'Report_Item_First_In_First_Out_' + dateNow + '.xlsx';
                var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
                ws['!cols'] = itemFirstInFirstOutMutationReportServices.itemFirstInFirstOutMutationReportExcelHeaderSize();

                var wb = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(wb, ws);
                XLSX.writeFile(wb, filename);
            }
        },
    },
}
</script>