import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class ForecastReportService {
    readForecastReportQuery(){
        var query = `
            query ($startDate: String!, $endDate: String!, $projecyType: Int, $forecastType: String, $customer: Int, $status: String) {
                GetReportingProjectForecast(
                    start_date: $startDate, 
                    end_date: $endDate, 
                    project_type: $projecyType, 
                    forecast_type: $forecastType, 
                    customer_id: $customer,
                    status: $status) 
                {
                    project_id
                    project_code
                    forecast_type
                    project_name
                    project_value
                    total_purchase_order_value
                    total_discount_cash
                    non_cash_total
                    remaining_purchase_order_value
                    purchase_order_numbers
                    sp_numbers
                    delivery_order_numbers
                    invoice_numbers
                    contact_names
                    status
                    project_date
                    project_type
                    project_type_name
                    reporting_project_forecast_detail {
                        project_id
                        item_id
                        item_name
                        quantity
                        price
                        purchase_order_numbers
                        delivery_order_numbers
                        invoice_numbers
                        remaining_purchase_order_quantity
                    }
                } 
            }`;
        return query;
    }

    async getCustomerQuery(){
        var query = gql`query{
            GetContact(ContactType:"Distributor"){
                contact_id
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var str = { value: result.data.GetContact[i].contact_id, 
                            label: result.data.GetContact[i].contact_name}
                arrayData.push(str);
            }
        }
        return arrayData;
    }   

    forecastReportExcelHeaderSize(){
        var size = [
            {width: 20}, //colA
            {width: 20}, //colB
            {width: 20}, //colC
            {width: 20}, //colD
            {width: 20}, //colE
            {width: 20}, //colF
            {width: 20}, //colG
            {width: 20}, //colH
            {width: 60}, //colI
            {width: 20}, //colJ
            {width: 20}, //colK
            {width: 20}, //colL
            {width: 20}, //colM
            {width: 20}, //colN
        ];
        return size;
    }

    forecastReportExcel(data, variables){
        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"_);_(@_)';

        var startIndex = 6;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : variables.projecyType + ' Forecast Report',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        var row6 = {
            colA : 'KODE PROJECT',
            colB : 'NAMA PROJECT',
            colC : 'NILAI PROJECT',
            colD : 'NILAI PO',
            colE : 'NILAI DISKON CASH',
            colF : 'NILAI SISA PROJECT',
            colG : 'NO. PO',
            colH : 'NO. SP',
            colI : 'NO. DO',
            colJ : 'NO. INVOICE',
            colK : 'CUSTOMER',
            colL : 'STATUS',
            colM : 'TIPE FORECAST',
            colN : 'TANGGAL',
        };
        arrayObject.push(row6);

        if(data != null){
            for (let i = 0; i < data.length; i++){
                var row = {
                    colA : data[i].project_code,
                    colB : data[i].project_name,
                    colC : { v: data[i].project_value, t: 'n' , z: numberFormat },
                    colD : { v: data[i].total_purchase_order_value, t: 'n' , z: numberFormat },
                    colE : { v: data[i].total_discount_cash, t: 'n' , z: numberFormat },
                    colF : { v: data[i].remaining_purchase_order_value, t: 'n' , z: numberFormat },
                    colG : data[i].purchase_order_numbers,
                    colH : data[i].sp_numbers,
                    colI : data[i].delivery_order_numbers,
                    colJ : data[i].invoice_numbers,
                    colK : data[i].contact_names,
                    colL : data[i].status,
                    colM : data[i].forecast_type,
                    colN : kendo.toString(kendo.parseDate(data[i].project_date, 'yyyy-MM-dd'), 'dd-MM-yyyy'),
                };
                
                arrayObject.push(row);
            }
        }

        return arrayObject;
    }
}


export default new ForecastReportService();