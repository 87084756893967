<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Report Forecast</h2>
            </CCardHeader>
            <CCardBody>
                <CRow>
                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tipe Tanggal</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <CSelect id="DateType" class="font-weight-bold" @change="onChangeDateType()" :options="DateTypeData" :value.sync="DateType" />
                                <label id="errorDateType" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold">Tipe Forecast</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="ForecastType" class="pb-3" :options="ForecastTypeData" v-model="ForecastType" @input="onChangeForecastType()"/>
                                <label id="errorForecastType" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>

                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Mulai</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <date-picker
                                    v-model="DateFrom"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    @change = "onChangeDateFrom"
                                ></date-picker>
                                <label id="errorDateFrom" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold pt-3">Customer</label>
                                <label class="form-label font-weight-bold pt-3" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="Customer" class="pt-3" :options="CustomerData" v-model="Customer" @input="onChangeCustomer()"/>
                                <label id="errorCustomer" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>

                    <CCol class="sm-3">
                        <CRow>
                            <CCol class="sm-2">
                                <label class="form-label font-weight-bold">Tanggal Akhir</label>
                                <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3">
                                <date-picker
                                    v-model="DateTo"
                                    format="DD MMM YYYY"
                                    type="date"
                                    placeholder="Pilih tanggal"
                                    value-type="YYYY-MM-DD"
                                    style="width:100%"
                                    @change = "onChangeDateTo"
                                ></date-picker>
                                <label id="errorDateTo" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol class="sm-2" >
                                <label class="form-label font-weight-bold pt-3">Status</label>
                                <label class="form-label font-weight-bold pt-3" style="float:right">:&nbsp;</label>
                            </CCol>
                            <CCol class="sm-3" >
                                <v-select id="Status" class="pt-3" :options="StatusData" v-model="Status" @input="onChangeStatus()"/>
                                <label id="errorStatus" class="form-error pb-2" style="display: none; color: red;"></label>
                            </CCol>
                        </CRow>
                    </CCol>
                </CRow>

                <hr>
                <CRow>
                    <CCol>
                        <button id="view" class="btn btn-primary" style="float:left"
                            @click="exportExcel()">
                            <i class="fa fa-file-excel-o"></i> Excel
                        </button>
                    </CCol>
                </CRow>

                <hr>
                <CRow>
                    <CCol>
                        <div class="col-xs-6 text-light pb-2">
                            <button id="ByProject" class="btn btn-secondary" @click="byProjectClick()">
                                By Project
                            </button>
                            &nbsp;
                            <button id="ByRetail" class="btn btn-secondary" @click="byRetailClick()">
                                By Retail
                            </button>
                        </div>
                        <forecast-report-grid ref="forecastReportGrid" 
                            :key="gridReload" 
                            :dateFrom="DateFromChangest" 
                            :dateTo="DateToChangest" 
                            :type="Type"
                            :forecastType="ForecastType"
                            :customer="Customer"
                            :status="Status"
                        />
                    </CCol>
                </CRow>
                
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import datePicker from 'vue2-datepicker';
import moment from 'moment';
import 'vue2-datepicker/index.css';
import { report } from '../../../infrastructure/constant/variable';
import forecastReportGrid from './Grid/ForecastReportGrid.vue';
import forecastReportService from './Script/ForecastReportService';

export default {
    name: 'ForecastReport',
    components: {
        'date-picker' : datePicker,
        'forecast-report-grid' : forecastReportGrid,
    },
    async mounted () {
        this.DateTypeData = this.$globalfunc.getDateTypeData();

        this.onChangeDateType();
        this.Type = "Project";
        this.ForecastType = null;
        this.CustomerData = await forecastReportService.getCustomerQuery();
        this.Customer = null;
        this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_project');
        this.Status = null
    },
    data() {
        return {
            DateTypeData: [],
            DateType: report.default_date,

            DateFrom: '',
            DateTo: '',

            DateFromChangest: report.default_start_date,
            DateToChangest: report.default_end_date,
            
            gridReload: 0,
            Type: '',
            ForecastTypeData: ["Forecast 1", "Forecast 2"],
            ForecastType: null,
            CustomerData: [],
            Customer: null,
            StatusData: [],
            Status: null,
        }
    },
    methods: {
        byProjectClick(){
            this.Type = 'Project';
            this.gridReload++;
        },
        byRetailClick(){
            this.Type = 'Retail';
            this.gridReload++;
        },
        onChangeForecastType(){
            this.gridReload++;
        },
        onChangeCustomer(){
            this.gridReload++;
        },
        onChangeStatus(){
            this.gridReload++;
        },
        async onChangeDateType(){
            var dateTypeData = this.$globalfunc.getDateTypeDetail(this.DateType);
            this.DateFrom = dateTypeData.startDate == null ? this.DateFrom : dateTypeData.startDate;
            this.DateTo = dateTypeData.endDate == null ? this.DateTo : dateTypeData.endDate;
            this.DateFromChangest = this.DateFrom;
            this.DateToChangest = this.DateTo
            this.gridReload++;
        },
        async onChangeDateFrom(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateFrom = this.DateFromChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }else{
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;
                this.gridReload++;
                this.DateType = 'Custom';
            }
        },
        async onChangeDateTo(){
            var vue = this;
            if((moment(this.DateFrom).valueOf()-moment(this.DateTo).valueOf()) > 0){
                this.DateTo = this.DateToChangest;
                vue.$swal("Error", "Tanggal awal tidak boleh lebih besar dari tanggal akhir", "error");
            }else{
                this.DateFromChangest = this.DateFrom;
                this.DateToChangest = this.DateTo;
                this.gridReload++;
                this.DateType = 'Custom';
            }
        },
        async exportExcel() {
            this.$refs.forecastReportGrid.exportExcel(this.DateFrom, this.DateTo, this.Type);
        },
    }
}
</script>

<style scoped>
.sm-2, .sm-3, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .sm-2, .sm-3, .col-sm-half {
        float: left;
    }
    .sm-2 {
        width: 12%;
    }
    .sm-3 {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
</style>