import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class ItemFirstInFirstOutMutationReportService {
    readItemFirstInFirstOutMutationReportQuery(){
        var query = `
            query ($startDate: String!, $endDate: String!, $item: String) {
                GetReportingStockMutationFirstInOut(
                        start_date: $startDate, 
                        end_date: $endDate, 
                        item_id: $item, ) 
                {
                    created_at
                    item_name
                    product_code
                    storage_id
                    quantity_change
                    last_base_stock
                    base_stock
                    purchasing_price
                    transaction_price
                    item_id
                    item_type
                    item_group_name
                    type_detail_name
                    stock_mutation_id
                    mut_reason
                    base_uom
                    ref_number
                    ref_date
                    stock_in
                    stock_out
                } 
            }`;
        return query;
    }

    async getItemQuery(){
        var query = gql`query{
            GetProductMaster{
                ProductMaster{
                    item_id
                    product_code
                    item_name
                }
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetProductMaster.ProductMaster;
        var itemData = [];
        for (let i = 0; i < data.length; i++) {
            var str = { value: data[i].item_id, 
                label: data[i].product_code + ' (' + data[i].item_name + ')'}
            itemData.push(str);
        }

        return itemData;
    }

    itemFirstInFirstOutMutationReportExcelHeaderSize(){
        var size = [
            {width: 60}, //colA
            {width: 20}, //colB
            {width: 20}, //colC
            {width: 20}, //colD
            {width: 20}, //colE
            {width: 20}, //colF
            {width: 20}, //colG
            {width: 20}, //colH
            {width: 20}, //colI
            {width: 20}, //colJ
        ];

        return size;
    }

    itemFirstInFirstOutMutationReportExcel(data, variables){
        const uniques = [];
        data.forEach((t) => !uniques.includes(t.product_code) && uniques.push({product_code : t.product_code, last_base_stock : t.last_base_stock, item_name : t.item_name}));

        var arrayObject = [];
        var date = 'Per ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0_);_(* (#,##0);_(* "-"_);_(@_)';

        var startIndex = 5;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS'};
        arrayObject.push(row1);

        var row2 = {colA : 'Item First In First Out Mutation'};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        if(data != null){
            for (let k= 0; k < uniques.length; k++) {     
                var headerRow1 = {
                    colA : "Kode Item : " + uniques[k].product_code + " (" + uniques[k].item_name + ")",
                    colB : "Current Stock : " + uniques[k].last_base_stock,
                };
                arrayObject.push(headerRow1);

                var headerRow2 = {
                    colA : 'Item Group',
                    colB : 'Tipe Detail',
                    colC : 'Gudang',
                    colD : 'Nama Item',
                    colE : 'Nomor Transaksi',
                    colF : 'Tanggal Transaksi',
                    colG : 'Stock Masuk',
                    colH : 'Stock Keluar',
                    colI : 'Satuan',
                    colJ : 'Harga',
                };
                arrayObject.push(headerRow2);

                var detail = data.filter(c => c.product_code == uniques[k].product_code);
                for (let i = 0; i < detail.length; i++){
                    var row = {
                        colA : detail[i].item_group_name,
                        colB : detail[i].type_detail_name,
                        colC : detail[i].storage_id,
                        colD : detail[i].item_name,
                        colE : detail[i].ref_number,
                        colF : detail[i].ref_date != '' ? { v: detail[i].ref_date, t: 'd', z: 'DD-MMM-YYYY' } : '-',
                        colG : detail[i].stock_in,
                        colH : detail[i].stock_out,
                        colI : detail[i].base_uom,
                        colJ : { v: detail[i].purchasing_price, t: 'n' , z: numberFormat}
                    };
                    arrayObject.push(row);
                }
                        
                var spaceRow = {colA : null};
                arrayObject.push(spaceRow);
            }
        }

        return arrayObject;
    }
}


export default new ItemFirstInFirstOutMutationReportService();