<template>
    <div>
        <kendo-grid
            ref="grid"
            :data-source="ForecastOtherReport"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            v-on:detailinit="detailInit"
            :resizable="true">
        </kendo-grid>
    </div>
</template>
  
  
<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import { kendo_grid, datetime_zone } from '../../../../infrastructure/constant/variable';
import moment from 'moment';
import ForecastOtherReportServices from '../Script/ForecastOtherReportService.js';
  
export default {
    name: 'ForecastOtherReportGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['dateTo', 'dateFrom', 'type', 'forecastType', 'status'],
    computed: {
        ForecastOtherReport () {
            var dateFrom = this.$props.dateFrom;
            var dateTo = this.$props.dateTo;
            var type = this.$props.type;
            var forecastType = this.$props.forecastType;
            var status = this.$props.status;

            return new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: false,
                serverFiltering: false,
                serverSorting: false,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const variables = {
                                startDate: dateFrom + datetime_zone.date_from,
                                endDate: dateTo + datetime_zone.date_to,
                                projecyType: type == "Project" ? 1 : 2,
                                forecastType: forecastType,
                                status: status == null ? null : status.label
                            };
                            return {
                                query: ForecastOtherReportServices.readForecastOtherReportQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) { 
                        if(response.data.GetReportingProjectNonePaketTotal == null) {
                            return [];
                        }
                        else {
                            return response.data.GetReportingProjectNonePaketTotal;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetReportingProjectNonePaketTotal == null) {
                            return 0;
                        }
                        else {
                            return response.data.GetReportingProjectNonePaketTotal.length;
                        }
                    },
                    model: {
                        fields: {
                            project_date: {type: "date"},
                            project_value: {type: "number" },
                        }
                    }
                }
            });
        }
    },
    data: function () {
        return  {
            columns:  [
                { field: "project_code", title: "Kode Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "project_name", title: "Nama Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "project_value", title: "Nilai Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}
                    ,format:"{0:N0}" },
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "forecast_type", title: "Tipe Forecast", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, },
                { field: "project_date", title: "Tanggal", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: "#= kendo.toString(kendo.parseDate(project_date, 'yyyy-MM-dd'), 'dd-MM-yyyy') #" },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.client_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    },
    mounted: async function() {

    },
    methods: {
        async exportExcel(DateFrom, DateTo, Type) {
            const variables = {
                startDate: DateFrom,
                endDate: DateTo,
                projecyType: Type
            };

            var gridData = this.$refs.grid.kendoWidget()._data;
            var data = await ForecastOtherReportServices.forecastOtherReportExcel(gridData, variables);
            if (gridData == null) {
                this.$swal("Error", response.noRecord, "error")
            }
            else {
                var XLSX = require("xlsx");
                var dateNow = moment(new Date()).format("YYYY-MM-DD-HHmmss");

                var filename = 'Report_Forecast_None_Paket_Total_' + dateNow + '.xlsx';
                var ws = XLSX.utils.json_to_sheet(data, {skipHeader: true});
                ws['!cols'] = ForecastOtherReportServices.forecastOtherReportExcelHeaderSize();

                var wb = XLSX.utils.book_new();

                XLSX.utils.book_append_sheet(wb, ws);
                XLSX.writeFile(wb, filename);
            }
        },
        detailInit: async function (e) {
            $('<div/>').appendTo(e.detailCell).kendoGrid({
                ref: "detailGrid",
                dataSource: {
                    transport: {
                        read: function (x) {
                            x.success(e.data.reporting_project_none_paket_total_detail);
                        },
                    },
                    parameterMap: function(options, operation) {
                        return kendo.stringify(
                            {
                                query: options.query,
                                variables: options.variables
                            }
                        );
                    },
                    schema: {
                        data: function (response) {
                            if(response == null)
                                return [];
                            else
                                return response;
                        },
                        total: function(response) {
                            if(response == null)
                                return 0;
                            else
                                return response.length;
                        }
                    },
                    pageSize: kendo_grid.default_detail_grid_pagesize,
                },
                scrollable: false,
                sortable: true,
                pageable: true,
                navigatable: true,
                editable: false,
                resizable: true,
                columns: [
                    { field: 'sp_number', title: "No. SP", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'purchase_order_number', title: "No. PO", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'contact_name', title: "Customer", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                    { field: 'purchase_order_value', title: "Nilai PO", width: 100, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"}
                        ,format:"{0:N0}" },
                ],
            });
        },
    },
}
</script>