import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class ForecastOtherReportService {
    readForecastOtherReportQuery(){
        var query = `
            query ($startDate: String!, $endDate: String!, $projecyType: Int, $forecastType: String, $status: String) {
                GetReportingProjectNonePaketTotal(start_date:$startDate, end_date:$endDate, project_type:$projecyType, forecast_type:$forecastType, status: $status) {
                    project_id
                    project_code
                    project_name
                    purchase_order_value
                    project_date
                    status
                    forecast_type
                    project_type
                    project_type_name
                    reporting_project_none_paket_total_detail {
                        project_id
                        sp_number
                        purchase_order_number
                        contact_name
                        purchase_order_value
                        purchase_order_date
                    }
                } 
            }`;
        return query;
    }

    async getCustomerQuery(){
        var query = gql`query{
            GetContact(ContactType:"Distributor"){
                contact_id
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var str = { value: result.data.GetContact[i].contact_id, 
                            label: result.data.GetContact[i].contact_name}
                arrayData.push(str);
            }
        }
        return arrayData;
    }   

    forecastOtherReportExcelHeaderSize(){
        var size = [
            {width: 20}, //colA
            {width: 20}, //colB
            {width: 20}, //colC
            {width: 20}, //colD
            {width: 20}, //colE
        ];
        return size;
    }

    forecastOtherReportExcel(data, variables){
        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"_);_(@_)';

        var startIndex = 6;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : variables.projecyType +'Forecast None Paket Total Report',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);

        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        var row6 = {
            colA : 'KODE PROJECT',
            colB : 'NAMA PROJECT',
            colC : 'NILAI PROJECT',
            colD : 'STATUS',
            colE : 'TIPE FORECAST',
            colF : 'TANGGAL',
        };
        arrayObject.push(row6);

        if(data != null){
            for (let i = 0; i < data.length; i++){
                var row = {
                    colA : data[i].project_code,
                    colB : data[i].project_name,
                    colC : { v: data[i].project_value == null ? 0 : data[i].project_value, t: 'n' , z: numberFormat },
                    colD : data[i].status,
                    colE : data[i].forecast_type,
                    colF : kendo.toString(kendo.parseDate(data[i].project_date, 'yyyy-MM-dd'), 'dd-MM-yyyy'),
                };
                
                arrayObject.push(row);
            }
        }

        return arrayObject;
    }
}


export default new ForecastOtherReportService();